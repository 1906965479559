import React, { useState } from "react";

function EKYCForm() {
  const [input, setInput] = useState("");
  const [error, setError] = useState("");
  const [iframeUrl, setIframeUrl] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!input.trim()) {
      setError("Input cannot be empty.");
      return;
    }

    try {
      const apiUrl = "https://kyc.dev.snapmint.com/kyc/v1/";
      const successUrl = `${window.location.origin}/ekyc/success`;
      const failureUrl = `${window.location.origin}/ekyc/failure`;

      const payload = {
        userId: input.trim(),
        kycType: "EKYC",
        successUrl,
        failureUrl,
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();

      if (data?.digilockerUrl) {
        setIframeUrl(data.digilockerUrl);
      } else {
        throw new Error("Invalid response from the server.");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
        <h1 className="text-2xl font-bold text-center mb-4">EKYC Process</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="userInput" className="block text-sm font-medium text-gray-700">
              Enter Your User ID
            </label>
            <input
              type="text"
              id="userInput"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          {error && <div className="text-red-500 text-sm">{error}</div>}
          <button
            type="submit"
            className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Submit
          </button>
        </form>
      </div>
      {iframeUrl && (
        <div className="w-full mt-6">
          <iframe
            src={iframeUrl}
            title="DigiLocker"
            className="w-full h-screen border rounded-md"
          />
        </div>
      )}
    </div>
  );
}

export default EKYCForm;
